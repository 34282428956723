import React from 'react';
import { useSelector } from 'react-redux';
import StarRating from './StarRating';

const Card4 = ({ image, name, text, raiting, company_id }) => {
  const getStr = (text) => {
    return text.replace(/<[^>]+>/g, ' ');
  };

  const splitText = (text) => {
    if (text.length > 200) {
      return text.substring(0, 200) + '...';
    } else {
      return text;
    }
  };

  const getNameText = (name) => {
    const splt = name.split(' ');
    let txt = '';
    txt += splt[0][0].toUpperCase();
    if (splt[1]) {
      txt += splt[1][0].toUpperCase();
    }

    return txt;
  };

  const contentData = useSelector((state) => state.Data.contentData);

  return (
    <div className='shadow-2xl gap-[15px] rounded-[8px] flex flex-col justify-between p-[35px] bg-[var(--white)]'>
      <div className='flex flex-col items-start gap-[20px]'>
        <div className='flex gap-[15px] items-center'>
          {image ? (
            <img className='w-[90px] h-[90px] rounded-[100%]' src={image} alt={name} />
          ) : (
            <span className='w-[90px] h-[90px] rounded-[100%] inline-flex justify-center items-center text-white text-[27px] font-[500] bg-blue-700'>
              {getNameText(name)}
            </span>
          )}

          <div className='flex flex-col items-start'>
            <h3 className='font-[600] text-[18px] text-[#313e3b] text-center'>{name}</h3>
            <span className='text-[16px] font-[400] text-[#828c8a]'>
              {contentData?.student}
            </span>
          </div>
        </div>
        <p className='font-[400] leading-[30px] text-[17px] text-[#828c8a] overflow-hidden inline-block'>
          {splitText(getStr(text))}
        </p>
      </div>

      <div className='flex justify-between mt-[6px] items-center'>
        <div className='flex items-center gap-[6px]'>
          <span className='font-[600] text-[17px] mt-[3px]'>{raiting}.0</span>
          <StarRating rating={raiting} />
        </div>
      </div>
    </div>
  );
};

export default Card4;

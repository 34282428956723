import React, { useEffect } from 'react'
import SubjectCard from '../subjects/SubjectCard'
import { useDispatch, useSelector } from 'react-redux'
import { getSubjects } from '../../actions/MainActions'
import { useTranslation } from 'react-i18next'

const HighSchoolDiploma = () => {
  const lang = useSelector(state=> state.Data.lang)
  const lang_id = useSelector(state => state.Data.lang_id)
  const Subjects = useSelector(state => state.Data.Subjects)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubjects(lang_id))
  }, [dispatch, lang_id])

  const {t} = useTranslation()
  return (
    <div className='grid grid-cols-4 gap-[20px] mt-[26px] container max-[1400px]:grid-cols-3 max-[990px]:grid-cols-2 max-[770px]:grid-cols-1 max-[770px]:place-items-center'>
      <SubjectCard
      subject={t("financial_accounting")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/17194785846446fde7b63dbe33f35f290c_623b9f191bc1d3d536b9141c_35.webp"}
      />
      <SubjectCard
      subject={t("international_business")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1719478650crucial-small-business-departments-need-to-succeed.webp"}
      />
      <SubjectCard
      subject={t("business_leadership")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484804leader.430b38401f04c47c13c9.jpg"}
      />
      <SubjectCard
      subject={t("enviroment_resource")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484821environment.7bb2ce07d0eb656257bf.jpg"}
      />
      <SubjectCard
      subject={t("canadian_world_issues")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484834studentincanada.396d3a897374f1fe0a41.jpg"}
      />
      <SubjectCard
      subject={t("analysing_current")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484850economicissues.afd471ac72ad59455ed8.jpg"}
      />
      <SubjectCard
      subject={t("canadian_international_law")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484868law-students-in-class.3109498ca2551361a978.jpeg"}
      />
      <SubjectCard
      subject={t("english_u")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1719478702iumw_english.jpg"}
      />
      <SubjectCard
      subject={t("nutrition_health")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484905nutriotion.8ca62e18acca1fcb3b7c.jpg"}
      />
      <SubjectCard
      subject={t("canada_families")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484918families.1e3d318ed2e51898bc4d.jpg"}
      />
      <SubjectCard
      subject={t("fashion")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484931fashion.3794ffd7f67cf651486d.jpg"}
      />
      <SubjectCard
      subject={t("change_society")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484944society.7ebb8349b6d1d64dd9c2.jpg"}
      />
      <SubjectCard
      subject={t("computer_science")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484958computer_science.d920ed8c24272f04fd0a.jpg"}
      />
      <SubjectCard
      subject={t("calculus_vectors")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484971Calculus-class.f1c5f8da11bf64c8df58.jpg"}
      />
      <SubjectCard
      subject={t("advanced_functions")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1719478830Math-lg.jpg"}
      />
      <SubjectCard
      subject={t("biology_u")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1719478873How-to-Study-Biology-5-Tips.png"}
      />
      <SubjectCard
      subject={t("physics_u")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1719478964teaching-physics-pendulum.jpg"}
      />
      <SubjectCard
      subject={t("chemistry_u")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1719478923istock_peers.jpg"}
      />
      <SubjectCard
      subject={t("communications_technology")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717485035comunication.31d4a8ec9073811735f1.png"}
      />

      {/* {
        Subjects.slice(10, 29).map(data => (
          <SubjectCard
          key={data?.id}
          subject={data?.translate.title}
          background={data?.image_full_url}
          />
        ))
      } */}
    </div>
  )
}

export default HighSchoolDiploma
import React, { useEffect } from 'react'
import OptionsCard from './OptionsCard'
import OptionSvgSetOne from '../../img/svg/OptionsSvgSetOne'
import OptionSvgSetTwo from '../../img/svg/OptionsSvgSetTwo'
import OptionScgSetThree from '../../img/svg/OptionSvgSetThree'
import { useDispatch, useSelector } from 'react-redux'
import { getOptions } from '../../actions/MainActions'
import { useTranslation } from 'react-i18next'

const OptionsParent = () => {
  const lang_id = useSelector(state => state.Data.lang_id)
  const Options = useSelector( state => state.Data.Options || [])
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOptions(lang_id))
  }, [dispatch, lang_id]);

  // console.log(Options);

  const {t} = useTranslation()
  return (
    <div className='bg-[var(--blue)]'>
      <div className='container p-[50px_0px] grid grid-cols-3 max-[1000px]:grid-cols-1 max-[1000px]:place-items-center max-[1000px]:gap-[20px]'>
        <OptionsCard
        icon={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/why_us/1717504815KC_Icon_Teachers.png"}
        title={t("international_teachers_title")}
        text_1={t("international_teachers_description_1")}
        text_2={t("international_teachers_description_2")}
        text_3={t("international_teachers_description_3")}
        />
        <OptionsCard
        icon={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/why_us/1717504843KC_Icon_Support.png"}
        title={t("individual_approach_title")}
        text_1={t("individual_approach_description_1")}
        text_2={t("individual_approach_description_2")}
        text_3={t("individual_approach_description_3")}
        />
        <OptionsCard
        icon={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/why_us/1717504905KC_Icon_ClassSize.png"}
        title={t("individual_approach_title")}
        text_1={t("vip_classes_description_1")}
        />
        {/* {
          Options.map(data => (
            <OptionsCard
            key={data.id}
            icon={data.image_full_url}
            title={data.translate.title}
            text_1={data.translate.text}
            />
          ))
        } */}
      </div>
    </div>
  )
}

export default OptionsParent
import React from 'react'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const {t} = useTranslation()
  return (
    <div className='mt-[35px]'>
        <div className='h-[1px] bg-[var(--dark-gray)]'></div>
        <div className='text-center p-[38px_0px]'><p className='text-[15px]'>© 2023 <span className='text-[var(--orange)]'>CELT Colleges</span>. {t("rights")}.</p></div>
    </div>
  )
}

export default Footer
import React, { useEffect } from 'react'
import CentersCard from './CentersCard'
import { useDispatch, useSelector } from 'react-redux'
import { getContent } from '../../actions/MainActions'
import { useTranslation } from 'react-i18next'

const CentersParentCont = () => {
  const lang_id = useSelector(state => state.Data.lang_id);
  const content = useSelector(state => state.Data.content);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getContent(lang_id));
  }, [dispatch, lang_id]);
  const {t} = useTranslation()
  return (
    <div className='grid grid-cols-2 gap-[20px] container max-[780px]:grid-cols-1 '>
      <CentersCard 
        center={t("bilgah")} 
        mobile={"050 371 3434"} 
        whatsapp_link={"https://wa.me/+994503713434"}
        location_link={"https://www.google.com/maps/place/CELT+High+School/@40.3694089,49.8276876,19z/data=!4m6!3m5!1s0x40307d25f9a6067d:0x8624d4910168579!8m2!3d40.3694055!4d49.8278814!16s%2Fg%2F11qgkk0qck?entry=ttu"}
        facebook_link={"https://www.facebook.com/celtschool/"}
        instagram_link={"https://www.instagram.com/celt.high.school/"}
        />
        <CentersCard 
        center={t("narimanov_statue")} 
        mobile={"050 211 35 56"} 
        whatsapp_link={"https://api.whatsapp.com/send/?phone=%2B994502113556&text&type=phone_number&app_absent=0"}
        location_link={"https://www.google.com/maps/place/CELT+High+School/@40.3694089,49.8276876,19z/data=!4m6!3m5!1s0x40307d25f9a6067d:0x8624d4910168579!8m2!3d40.3694055!4d49.8278814!16s%2Fg%2F11qgkk0qck?entry=ttu"}
        facebook_link={"https://www.facebook.com/celtschool/"}
        instagram_link={"https://www.instagram.com/celt.high.school/"}
        />
    </div>
  )
}

export default CentersParentCont
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CloseSvg from '../img/svg/CloseSvg';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { postStatistics } from '../actions/MainActions';
import UserSvg1 from '../img/svg/UserSvg1';
import SubjectSvg1 from '../img/svg/SubjectSvg1';
import LearningSvg1 from '../img/svg/LearningSvg1';
import PhoneSvg4 from '../img/svg/PhoneSvg4';
import SuccessPopup from './SuccessPopup';
import { changeStateValue } from '../redux/MainReducer';

const PopupComponent = ({
  show,
  onClose,
  children,
  contentData,
  postStatistics,
  changeStateValue,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    education_type: null,
    company_id: '',
    subject: '',
    phone: '',
    contacsPage: null,
    errors: {},
    successMessage: '',
    showSuccessPopup: false,
    btnDisable: false,
  });

  const {
    name,
    education_type,
    company_id,
    subject,
    phone,
    contacsPage,
    errors,
    successMessage,
    showSuccessPopup,
    btnDisable,
  } = formData;

  const togglePopup = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      showPopup: !prevFormData.showPopup,
    }));
  };

  const onClickPageSchool = (index) => {
    let educationType = index === 1 ? 1 : 2;
    setFormData((prevFormData) => ({
      ...prevFormData,
      contacsPage: index,
      education_type: educationType,
    }));
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangePhone = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };

  const handleSubmit = () => {
    const { name, education_type, company_id, subject, phone } = formData;
    let errors = {};

    if (!name) {
      errors.name = 'Adınızı qeyd edin';
    }
    if (education_type === null) {
      errors.education_type = 'Necə oxumaq istədiyinizi qeyd edin';
    }
    if (education_type === 2 && !company_id) {
      errors.company_id = 'Bir mərkəz seçin';
    }
    if (!subject) {
      errors.subject = 'Oxumaq istədiyiniz fənni seçin';
    }
    if (!phone) {
      errors.phone = 'Telefon nömrənizi qeyd edin';
    }

    if (Object.keys(errors).length > 0) {
      setFormData({
        ...formData,
        errors: errors,
      });
      return;
    }

    const data = {
      name,
      education_type,
      company_id: education_type === 1 ? null : company_id,
      subject,
      phone_number: phone,
    };

    setFormData({
      ...formData,
      btnDisable: true,
    });

    postStatistics(data)
      .then((resp) => {
        if (resp === 'success') {
          setFormData({
            ...formData,
            successMessage: '',
            errors: {},
            showSuccessPopup: true,
          });
          changeStateValue({ name: 'popUpVisible', value: true });
          onClose();
          setFormData({
            ...formData,
            btnDisable: false,
          });
        } else {
          // handle error case
        }
      })
      .catch((error) => {
        setFormData({
          ...formData,
          successMessage: '',
          errors: { form: 'Form submission failed!' },
        });
      });
  };

  if (!show) {
    return null;
  }

  return (
    <div>
      <div className='popup-bg ' onClick={onClose}>
        <div className='popup rounded-[8px] max-w-[578px] flex flex-col gap-[5px] m-[16px]' onClick={(e) => e.stopPropagation()}>
          {children}
          <div className='flex justify-between border-b-[2px] '>
            <div></div>
            <div className='flex justify-between w-[68%]'>
              <h3 className='font-[600] text-[#032552] p-[22px_0px] text-[24px] text-center max-w-[500px]'>
                {contentData?.popup_registration}
              </h3>
              <div className='flex items-start '>
                <button onClick={onClose} className='m-[7px]'>
                  <CloseSvg />
                </button>
              </div>
            </div>
          </div>
          <div className='p-[12px] grid grid-cols-2 gap-[20px]'>
            <div className='flex flex-col gap-[8px]'>
              <div className='flex items-center gap-[5px]'>
                <div>
                  <UserSvg1 />
                </div>
                <span className='text-[14px] font-[550] text-[#000]'>{contentData?.popup_name}</span>
                {errors.name && <span className='text-red-500 text-[12px]'>{errors.name}</span>}
              </div>
              <div className='flex flex-col'>
                <div className='flex items-center border-[1px] rounded-[8px] p-[10px]'>
                  <input
                    className='w-full space-grotesk'
                    placeholder={contentData?.popup_name}
                    name='name'
                    value={name}
                    onChange={handleChange}
                    id='name'
                  />
                </div>
              </div>
            </div>
            <div className='flex flex-col gap-[8px]'>
              <div className='flex items-center gap-[5px]'>
                <div>
                  <SubjectSvg1 />
                </div>
                <span className='text-[14px] font-[550] text-[#000]'>{contentData?.popup_subject}</span>
                {errors.subject && <span className='text-red-500 text-[12px]'>{errors.subject}</span>}
              </div>
              <div className='w-full'>
                <select
                  className='w-full p-[10px] font-[500] text-[16px] border-[1px] rounded-[8px]'
                  name='subject'
                  value={subject}
                  onChange={handleChange}
                >
                  <option value=''>Seçin</option>
                  <option value='Beynəlxalq məktəb diplomu'>Beynəlxalq məktəb diplomu</option>
                  <option value='Ümumi ingilis dili'>Ümumi ingilis dili</option>
                  <option value='IELTS hazırlığı'>IELTS hazırlığı</option>
                  <option value='TOEFL hazırlığı'>TOEFL hazırlığı</option>
                  <option value='Duolingo hazırlığı'>Duolingo hazırlığı</option>
                  <option value='SAT hazırlığı'>SAT hazırlığı</option>
                  <option value='Riyaziyyat hazırlığı'>Riyaziyyat hazırlığı</option>
                  <option value='Olimpiada hazırlığı'>Olimpiada hazırlığı</option>
                  <option value='Uşaqlar üçün hazırlıq'>Uşaqlar üçün hazırlıq</option>
                  <option value='Digər sahələr'>Digər sahələr</option>
                </select>
              </div>
            </div>
          </div>
          <div className='p-[0px_12px] flex flex-col gap-[5px]'>
            <div className='flex flex-col gap-[5px]'>
              <div className='flex items-center gap-[5px]'>
                <span className='text-[#072465] text-[14px] font-[600]'>
                  {contentData?.how_do_you_want_to_read}
                </span>
                {errors.education_type && <span className='text-red-500 text-[12px]'>{errors.education_type}</span>}
              </div>
              <div className='grid grid-cols-2 gap-[24px] w-full max-[400px]:grid-cols-1 max-[400px]:gap-[18px]'>
                <button
                  onClick={() => onClickPageSchool(2)}
                  className={`${
                    contacsPage === 2 ? 'text-white bg-[#264EA9]' : 'text-[#072465] border-[#92B3FF]'
                  } p-[8px_0px] rounded-[50px] border-[1px] text-[15px] font-[500]`}
                >
                  {contentData?.in_center}
                </button>
                <button
                  onClick={() => onClickPageSchool(1)}
                  className={`${
                    contacsPage === 1 ? 'text-white bg-[#264EA9]' : 'text-[#072465] border-[#92B3FF]'
                  } p-[8px_0px] rounded-[50px] border-[1px] text-[15px] font-[500]`}
                >
                  {contentData?.popup_online}
                </button>
              </div>
            </div>
            <div className='grid grid-cols-2 gap-[20px] mt-[12px]'>
              {contacsPage === 2 ? (
                <div className='flex flex-col gap-[4px]'>
                  <div className='flex items-center gap-[5px]'>
                    <div>
                      <LearningSvg1 fill='#264EA9' />
                    </div>
                    <span className='text-[14px] font-[550] text-[#000]'>{contentData?.popup_select_center}</span>
                  </div>
                  {errors.company_id && <span className='text-red-500 text-[12px]'>{errors.company_id}</span>}
                  <div>
                    <select
                      className='w-full p-[10px] font-[500] text-[16px] border-[1px] rounded-[8px]'
                      name='company_id'
                      value={company_id}
                      onChange={handleChange}
                      id='company_id'
                    >
                      <option value=''>Seçin</option>
                      <option value='1'>CELT High School</option>
                      <option value='2'>CELT Colleges Khatai</option>
                      <option value='13'>CELT Colleges Hazi</option>
                      <option value='11'>CELT Colleges Ingilab</option>
                      <option value='6'>CELT Colleges Sahil</option>
                      <option value='7'>CELT Colleges Bakikhanov</option>
                      <option value='14'>CELT Colleges Sumgait</option>
                      <option value='5'>CELT Colleges Icharishahar</option>
                      <option value='9'>CELT Colleges Ajami</option>
                      <option value='4'>CELT Colleges 28 May</option>
                      <option value='8'>CELT Colleges Nizami</option>
                      <option value='33'>CELT Ağ Şəhər</option>
                      <option value='30'>CELT Colleges Ganjlik</option>
                      <option value='12'>CELT Colleges Elmler</option>
                      <option value='10'>CELT Colleges Narimanov</option>
                    </select>
                  </div>
                </div>
              ) : (
                <div className='flex flex-col gap-[4px]'>
                  <div className='flex items-center gap-[5px]'>
                    <div>
                      <LearningSvg1 fill='gray' />
                    </div>
                    <span className='text-[14px] font-[550] text-[gray]'>{contentData?.popup_select_center}</span>
                    {errors.company_id && <span className='text-red-500 text-[12px]'>{errors.company_id}</span>}
                  </div>
                  <div>
                    <div className='w-full p-[10px] font-[500] text-[16px] border-[1px] rounded-[8px]'>
                      <span className='text-[gray] ml-[4px]'>Seçin</span>
                    </div>
                  </div>
                </div>
              )}

              <div className='flex flex-col w-full gap-[5px] '>
                <div className='flex flex-col gap-[5px]'>
                  <div className='flex items-center gap-[5px]'>
                    <div>
                      <PhoneSvg4 />
                    </div>
                    <span className='text-[14px] font-[550] text-[#000]'>{contentData?.phone_number}</span>
                  </div>
                  {errors.phone && <span className='text-red-500 text-[12px]'>{errors.phone}</span>}
                </div>
                <div className='border-[1px] w-full rounded-[8px] p-[3px]'>
                  <PhoneInput
                    containerStyle={{
                      border: 'none',
                      margin: '0',
                    }}
                    inputStyle={{
                      backgroundColor: '#f2f2f2',
                      padding: '0px',
                      marginLeft: '38px',
                      borderRadius: '5px',
                      border: 'none',
                      background: 'none',
                    }}
                    buttonStyle={{
                      backgroundColor: 'transparent',
                      border: 'none',
                    }}
                    dropdownStyle={{
                      borderRadius: '0px',
                    }}
                    country={'az'}
                    value={phone}
                    onChange={handleChangePhone}
                    className='custom-flag'
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='flex justify-center w-full p-[0px_12px] mt-[32px] mb-[20px]'>
              <button
                disabled={btnDisable}
                onClick={handleSubmit}
                className='bg-[#EF7000] w-full p-[12px_0px] text-white rounded-[8px] text-[15px] font-[400]'
              >
                {contentData?.submit}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Success Popup */}
      {/* {showSuccessPopup && <SuccessPopup />} */}
    </div>
  );
};

PopupComponent.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  contentData: PropTypes.object,
//   postStatistics: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData,
});

const mapDispatchToProps = {
//   postStatistics,
  changeStateValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupComponent);

import React, { useEffect, useState } from 'react';
import HighSchoolDiploma from './HighSchoolDiploma';
import ALevelDiploma from './ALevelDiploma';
import CeltApDiploma from './CeltApDiploma';
import { useDispatch, useSelector } from 'react-redux';
import { getContent } from '../../actions/MainActions';
import { useTranslation } from 'react-i18next';

const SubjectsParentContainer = () => {
  const lang = useSelector((state) => state.Data.lang);
  const lang_id = useSelector((state) => state.Data.lang_id);
  const content = useSelector((state) => state.Data.content);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContent(lang_id));
  }, [dispatch, lang_id]);

  const [activeTab, setActiveTab] = useState(1);

  const {t} = useTranslation()

  return (
    <div className="p-5">
      <div className="flex justify-center gap-[20px] m-[20px_0px_40px_0px]">
        <button
          className={`p-[10px_15px] rounded shadow ${
            activeTab === 1 ? 'bg-[var(--orange)] text-white' : 'bg-gray-200'
          }`}
          onClick={() => setActiveTab(1)}
        >
          {t("a_level_diploma_title")}
        </button>
        <button
          className={`p-[10px_15px] rounded shadow ${
            activeTab === 2 ? 'bg-[var(--orange)] text-white' : 'bg-gray-200'
          }`}
          onClick={() => setActiveTab(2)}
        >
          {t("hs_diploma_title")}
        </button>
        <button
          className={`p-[10px_15px] rounded shadow ${
            activeTab === 3 ? 'bg-[var(--orange)] text-white' : 'bg-gray-200'
          }`}
          onClick={() => setActiveTab(3)}
        >
          {t("ap_diploma")}
        </button>
      </div>
      <div>
        {activeTab === 1 && <ALevelDiploma />}
        {activeTab === 2 && <HighSchoolDiploma />}
        {activeTab === 3 && <CeltApDiploma />}
      </div>
    </div>
  );
};

export default SubjectsParentContainer;

import React, { useEffect } from 'react'
import PageHeader from '../components/PageHeader'
import StudentParentCont from '../components/students/StudentParentCont'
import { useDispatch, useSelector } from 'react-redux'
import { getContent } from '../actions/MainActions'
import GraduatedStudents from '../components/students/GraduatedStudents'
import { useTranslation } from 'react-i18next'

const StudentsPage = () => {
  const lang = useSelector(state=> state.Data.lang)
  const lang_id = useSelector(state => state.Data.lang_id);
  const content = useSelector(state => state.Data.content);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContent(lang_id));
  }, [dispatch, lang_id]);

  const {t} = useTranslation()
  return (
    <div className='pt-[100px]'>
      <PageHeader page_title={t("nav_3")}/>
      {/* <StudentParentCont/> */}
      <GraduatedStudents/>
    </div>
  )
}

export default StudentsPage
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="#fff"
    viewBox="0 -4.5 20 20"
    {...props}
  >
    <title>{"arrow_up [#340]"}</title>
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M0 9.61 1.405 11l8.608-8.261.918.88-.005-.004 7.647 7.34L20 9.586 10.013 0 0 9.61"
    />
  </svg>
)
export default SvgComponent

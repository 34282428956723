import React from 'react'

const UniversitySvg = () => {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        data-name="Layer 1"
        viewBox="0 0 24 24"
        fill='var(--blue)'
        
      >
        <path d="M7 14c2.21 0 4-1.79 4-4S9.21 6 7 6s-4 1.79-4 4 1.79 4 4 4Zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2Zm7 15c0 .55-.45 1-1 1s-1-.45-1-1c0-2.76-2.24-5-5-5s-5 2.24-5 5c0 .55-.45 1-1 1s-1-.45-1-1c0-3.86 3.14-7 7-7s7 3.14 7 7ZM24 5v8c0 2.76-2.24 5-5 5h-4c-.55 0-1-.45-1-1s.45-1 1-1h4c1.65 0 3-1.35 3-3V5c0-1.65-1.35-3-3-3H9.46c-1.07 0-2.06.58-2.6 1.5-.28.48-.89.64-1.37.37a.998.998 0 0 1-.36-1.37C6.03.96 7.69 0 9.46 0H19c2.76 0 5 2.24 5 5Zm-10.33 5.92L16.59 8H15c-.55 0-1-.45-1-1s.45-1 1-1h3c1.1 0 2 .9 2 2v3c0 .55-.45 1-1 1s-1-.45-1-1V9.41l-2.92 2.92a2.424 2.424 0 0 1-2.37.62.997.997 0 0 1-.69-1.23c.15-.53.7-.84 1.24-.69.12.03.28.02.41-.11Z" />
      </svg>
  )
}

export default UniversitySvg
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import SectionHeader from '../SectionHeader';
import Card13 from '../Card13';
import LoadMoreBtn from '../btn/LoadMoreBtn';
import { Helmet } from 'react-helmet';
// import favicon from './celt-title.jpeg';
import { getGraduats } from '../../actions/MainActions';

const GraduatedStudents = ({ graduatsData, contentData, getGraduats }) => {
  useEffect(() => {
    getGraduats(1);
    window.scrollTo(0, 0);
  }, [getGraduats]);

  const loadMore = (page) => {
    getGraduats(page);
  };

  return (
    <div className='container'>
      <Helmet>
        
        <meta property="og:locale" content="az_AZ" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Xaricdə Tələbələrimiz" />
        <meta
          property="og:description"
          content="Seçilmiş Məzunlarımız M. Aliyeva Diplom: High School Ortalama: 90 Məktəb: CELT High School   Bakalavr: Economics and Business T. Ocağı: University of Amsterdam Ölkə: Holladiya F. Huseynov Diplom: High School &hellip; Xaricdə Tələbələrimiz Davamını oxu»"
        />
        <meta property="og:url" content="https://celt.az/xaricde-oxuyanlar/" />
        <meta property="og:site_name" content="CELT Colleges" />
        <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
        <meta property="article:modified_time" content="2023-06-15T11:35:17+00:00" />
        <meta property="og:image" content="https://celt.az/static/media/celtLogo.a95db1d2d66058755538.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@celtcolleges" />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="23 minutes" />
        {/* <link id="favicon" rel="icon" href={favicon} type="image/x-icon" /> */}
      </Helmet>

      <div className=' grid grid-cols-4 gap-[8px] max-[1255px]:grid-cols-3 max-[900px]:grid-cols-2 max-[580px]:grid-cols-1'>
        {graduatsData.slice(0, 8).map((data, i) => (
          <Card13
            key={i}
            first_name={data?.first_name}
            last_name={data?.last_name}
            company_name={data?.company_name}
            image_full_url={data?.inst_image?.image_full_url}
            country_image={data?.country_image}
            country_name={data?.country_name}
            user_image={data?.user_image}
            program_name={data?.program_name}
            inst_name={data?.inst_name}
            ranking={data?.ranking}
            grade={data?.grade}
            company_id={data?.company_id}
          />
        ))}
      </div>

    </div>
  );
};

const mapStateToProps = (state) => ({
  graduatsData: state.Data.graduatsData,
  contentData: state.Data.contentData,
});

export default connect(mapStateToProps, { getGraduats })(GraduatedStudents);

import axios from 'axios';
import { MAIN_API, CELT_GO, second_api } from "../api";
import { changeStateValue } from "../redux/MainReducer";


export const getStudents = () => async dispatch =>{
    return await axios.get(`${CELT_GO}/internation-diplomas`)
    .then(resp => {
        // console.log(resp.data);
        dispatch(changeStateValue({
            name:"students",
            value: resp.data
        }))
    }).catch(err => {console.log(err)})
}

export const getGraduats=(page=1, company_id="")=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${CELT_GO}/high-sch-accepted-students`,{
    params:{page, company_id}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'graduatsData',
            value:resp?.data
        }))
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}
export const getIntDiploma = (lang_id) => async dispatch =>{
    return await axios.get(`${MAIN_API}/internation-diplomas?lang_id=${lang_id}`)
    .then(resp => {
        // console.log(resp.data);
        dispatch(changeStateValue({
            name:"internationalDiploma",
            value: resp.data
        }))
    }).catch(err => {console.log(err)})
}

export const getOptions = (lang_id) => async dispatch =>{
    return await axios.get(`${MAIN_API}/why-us?lang_id=${lang_id}`)
    .then(resp => {
        // console.log(resp.data);
        dispatch(changeStateValue({
            name: "Options",
            value: resp.data
        }))
    }).catch(err => {console.log(err);})
}

export const getContent = (lang_id) => async dispatch =>{
    return await axios.get(`${MAIN_API}/content?lang_id=${lang_id}`)
    .then(resp => {
        // console.log(resp.data);
        dispatch(changeStateValue({
            name: "content",
            value: resp.data
        }))
    }).catch(err => {console.log(err);})
}

export const getSubjects = (lang_id) => async dispatch =>{
    return await axios.get(`${MAIN_API}/subjects?lang_id=${lang_id}`)
    .then(resp => {
        // console.log(resp.data);
        dispatch(changeStateValue({
            name: "Subjects",
            value: resp.data
        }))
    }).catch(err => {console.log(err);})
}

export const getstudentsReviewsData=(lang_id="1", count, company_id="")=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${second_api}/student-reviews`,{
        params:{lang_id:lang_id, count, company_id}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'studentsReviewsData',
            value:resp?.data
        }))
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))})
}

export const postStudyAbroad = (data) => async (dispatch) => {
    const { name, phone_number, country, university, blog } = data;

    const postData = {
        name,
        phone_number,
        country,
        university,
        blog,
    };

    dispatch(changeStateValue({ name: 'loader', value: true }));

    try {
        await axios.post(`${second_api}/student-apply`, postData);
        dispatch(changeStateValue({ name: 'loader', value: false }));
        return "success";
    } catch (err) {
        dispatch(changeStateValue({ name: 'loader', value: false }));
        return "error";
    }
};

export const postStatistics = (data) => async dispatch => {
    const { name, education_type, company_id, subject, phone_number, school_type } = data;
  
    const postData = {
      name,
      education_type,
      school_type,
      company_id: education_type === 1 ? null : company_id,
      subject,
      phone_number
    };
  
    dispatch(changeStateValue({ name: 'loader', value: true }));
    
    try {
      await axios.post(`${second_api}/online-apply`, postData);
      dispatch(changeStateValue({ name: 'loader', value: false }));
      return "success";
    } catch (error) {
      console.error(error.response);
      dispatch(changeStateValue({ name: 'loader', value: false }));
      return "error";
    }
  };

// export const postStatistics = (data) => async dispatch => {
//     const { name,  education_type, company_id, subject, phone_number, school_type } = data;

//     // Parametreleri hazırlayalım
//     const postData = {
//         name,
//         education_type,
//         school_type,
//         company_id: education_type === 1 ? null : company_id, // Online ise null, offline ise company_id
//         subject,
//         phone_number
//     };


//     dispatch(changeStateValue({ name: 'loader', value: true }));
//     return await axios.post(`${second_api}/online-apply`, postData)
//     .then(resp=>{   
//         dispatch(changeStateValue({name:'loader', value:false}))
//         return "success";
//     }).catch(err=>{
//         console.log(err.response)
//         dispatch(changeStateValue({name:'loader', value:false}))
//         return "error"
//     })
// };



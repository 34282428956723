import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend) 
  .use(LanguageDetector) 
  .use(initReactI18next) 
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    fallbackLng: 'az', // Default language
    debug: true,
    detection: {
      order: ['path', 'localStorage', 'cookie', 'navigator'],
      caches: ['localStorage', 'cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  });




// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

// i18n
//   .use(Backend) // Подключаем бэкенд для загрузки переводов
//   .use(LanguageDetector) // Подключаем определение языка
//   .use(initReactI18next) // Интеграция с React
//   .init({
//     backend: {
//       loadPath: '/locales/{{lng}}/translation.json', // Путь к JSON-файлам с переводами
//     },
//     fallbackLng: 'az', // Язык по умолчанию
//     debug: true,
//     detection: {
//       order: ['path', 'localStorage', 'cookie', 'navigator'], // Определение языка через URL и другие способы
//       caches: ['localStorage', 'cookie'],
//     },
//     interpolation: {
//       escapeValue: false, // Отключаем экранирование для React
//     },
//   });

// export default i18n;


// import i18n from "i18next"
// import LangauageDetector from 'i18next-browser-languagedetector'
// import { initReactI18next } from "react-i18next"
// import Backend from 'i18next-http-backend'

// i18n.use(LangauageDetector).use(initReactI18next).use(Backend).init({
//     debug: true,
//     lng: "az",

// })
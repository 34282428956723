import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import HomePage from "./pages/HomePage";
import  SubjectsPage from "./pages/SubjectsPage";
import StudentsPage from "./pages/StudentsPage";
import CentersPage from "./pages/CentersPage";
import Footer from "./components/Footer";
import ScrollIcon from "./components/ScrollIcon";
import ScrollToTop from "./ScrollToTop";
import { useTranslation } from 'react-i18next';
import Loader from "./pages/Loader";

// Компонент для динамического переключения языка
const LanguageWrapper = ({ children }) => {
  const { i18n } = useTranslation();
  const lang = window.location.pathname.split('/')[1]; // Извлечение языка из URL

  useEffect(() => {
    if (i18n.language !== lang && lang) {
      i18n.changeLanguage(lang); // Переключение языка на основе URL
    }
  }, [lang, i18n]);

  return children;
};

const App = () => {
  const [isLoading, setIsLoading] = useState(true); // Состояние для отслеживания загрузки

  // Эффект, который имитирует задержку загрузки перед отображением контента
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Скрыть загрузку через 2 секунды
    }, 1500);
    return () => clearTimeout(timer); // Очистка таймера при размонтировании
  }, []);

  return (
    <BrowserRouter>
      {isLoading ? ( // Если идёт загрузка, показываем спиннер
        <Loader /> // Компонент спиннера
      ) : ( // Иначе отображаем сайт
        <>
          <Navbar />
          <ScrollIcon />
          <ScrollToTop />
          <LanguageWrapper>
            <Routes>
              {/* Redirect to Azerbaijani (/) if there's no language in the URL */}
              <Route path="/" element={<HomePage />} />
              <Route path="/subjects" element={<SubjectsPage />} />
              <Route path="/graduates" element={<StudentsPage />} />
              <Route path="/schools" element={<CentersPage />} />
              
              {/* Routes for other languages */}
              <Route path="/:lang" element={<HomePage />} />
              <Route path="/:lang/subjects" element={<SubjectsPage />} />
              <Route path="/:lang/graduates" element={<StudentsPage />} />
              <Route path="/:lang/schools" element={<CentersPage />} />
            </Routes>
          </LanguageWrapper>
          <Footer />
        </>
      )}
    </BrowserRouter>
  );
};

export default App;

import React from 'react'
import logo from '../img/logo.png'

const LoadingPage = () => {
  return (
    <div className='flex items-center justify-center w-full h-[100vh]'>
        <img className='w-[200px]' src={logo}/>
    </div> 
  )
}

export default LoadingPage
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { setupStore } from './redux/store';
import "./i18n"
import LoadingPage from './pages/LoadingPage';
let store = setupStore()


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.Suspense fallback={<LoadingPage/>}>
    <App />
    </React.Suspense>
  </Provider>
);

import React, { useEffect } from 'react'
import SubjectCard from './SubjectCard'
import { getSubjects } from '../../actions/MainActions'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const CeltApDiploma = () => {
  const lang_id = useSelector(state => state.Data.lang_id)
  const Subjects = useSelector(state => state.Data.Subjects || [])
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubjects(lang_id))
  }, [dispatch, lang_id])

  const {t} = useTranslation()
  return (
    <div className='grid grid-cols-4 gap-[20px] mt-[26px] container max-[1400px]:grid-cols-3 max-[990px]:grid-cols-2 max-[770px]:grid-cols-1 max-[770px]:place-items-center'>
      <SubjectCard
          subject={t("english")}
          background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1718688656englang.71afa152e4df54ac7e12.jpg"}
      />
      <SubjectCard
          subject={t("english_and_letr")}
          background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1718688698society.7ebb8349b6d1d64dd9c2.jpg"}
      />
      <SubjectCard
          subject={t("mathematics")}
          background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1718688751math.4d4ffe0d161cfe01a90d.jpg"}
      />
      <SubjectCard
          subject={t("micro")}
          background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1718688795economics.91393cd76813880bd405.jpg"}
      />
      <SubjectCard
          subject={t("macro")}
          background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1719479015Zich_20Aug2022_86.jpg"}
      />
      <SubjectCard
          subject={t("goverment")}
          background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1718688871business2.c5a6a44113cd29e44b7f.jpg"}
      />
      <SubjectCard
          subject={t("europe_history")}
          background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1718688979shegstory_0.jpg"}
      />
      <SubjectCard
          subject={t("human_geography")}
          background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1718689078B%C3%BCcher_Humangeographie.jpg"}
      />
      <SubjectCard
          subject={t("psychology")}
          background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1718689180iStock-507009337-171002.png"}
      />
      <SubjectCard
          subject={t("usa_goverment")}
          background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1718689222WHS-Government-Class-2018-3.jpg"}
      />
      <SubjectCard
          subject={t("modern_world_history")}
          background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1718689368GettyImages-658984513-aa5cc46.jpg"}
      />
      <SubjectCard
          subject={t("usa_history")}
          background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1718689412635859742474759015-williamson-classs.webp"}
      />
      <SubjectCard
          subject={t("computer_science")}
          background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1718689468IT.66ea7b7498bcc306f8ab.jpg"}
      />
      <SubjectCard
          subject={t("statistics")}
          background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1718689517leader.430b38401f04c47c13c9.jpg"}
      />
      <SubjectCard
          subject={t("environment")}
          background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1718689667comunication.31d4a8ec9073811735f1.png"}
      />
      <SubjectCard
          subject={t("biology")}
          background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1718689544biology.7ea30f2b023b04ce5371.jpg"}
      />
      <SubjectCard
          subject={t("chemistry")}
          background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1718689568chemistry.d6fb133598a650f39707.jpg"}
      />
      <SubjectCard
          subject={t("physics")}
          background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1718689723learning_asst_lead.jpg"}
      />

      {/* {
        Subjects.slice(29, 47).map(data => (
          <SubjectCard
          key={data.id}
          subject={data.translate.title}
          background={data.image_full_url}
          />
        ))
      } */}
  </div>
  )
}

export default CeltApDiploma
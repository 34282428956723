import React from 'react'
import OptionSvgSetOne from '../../img/svg/OptionsSvgSetOne'
import OptionSvgSetTwo from '../../img/svg/OptionsSvgSetTwo'
import OptionScgSetThree from '../../img/svg/OptionSvgSetThree'

const OptionsCard = ({icon, title, text_1, text_2, text_3}) => {
  return (
    <div className='flex flex-col items-center max-[1000px]:max-w-[400px] p-[0px_25px]'>
        <img src={icon} className='w-[117px]'/>
        <div className='flex flex-col gap-[26px] mt-[26px]'>
            <p className='text-[var(--white)] font-[700] text-[32px] text-center'>
                {title}
            </p>
            <p  className='text-[var(--white)]'>
            {/* dangerouslySetInnerHTML={{__html:text_1}} */}
            {text_1}
            </p>
            <p className='text-[var(--white)]'>
                {text_2}
            </p>
            <p className='text-[var(--white)]'>
                {text_3}
            </p>
        </div>
    </div>
  )
}

export default OptionsCard
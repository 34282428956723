import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Card13 from '../Card13';
import LoadMoreBtn from '../btn/LoadMoreBtn';
import { getGraduats } from '../../actions/MainActions';

const GraduatedStudents = ({ graduatsData, contentData, getGraduats }) => {
  useEffect(() => {
    getGraduats(1);
    window.scrollTo(0, 0);
  }, [getGraduats]);

  const loadMore = (page) => {
    getGraduats(page);
  };

  return (
    <div className='container'>
      <div className='container grid grid-cols-4 gap-[8px] max-[1255px]:grid-cols-3 max-[900px]:grid-cols-2 max-[580px]:grid-cols-1'>
        {graduatsData?.map((data, i) => (
          <Card13
            key={i}
            first_name={data?.first_name}
            last_name={data?.last_name}
            company_name={data?.company_name}
            image_full_url={data?.inst_image?.image_full_url}
            country_image={data?.country_image}
            country_name={data?.country_name}
            user_image={data?.user_image}
            program_name={data?.program_name}
            inst_name={data?.inst_name}
            ranking={data?.ranking}
            grade={data?.grade}
            company_id={data?.company_id}
          />
        ))}
      </div>
      {graduatsData?.length >= 20 ? <LoadMoreBtn onLoadData={loadMore} defCount={20} count={graduatsData?.length} /> : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  graduatsData: state.Data.graduatsData,
  contentData: state.Data.contentData,
});

export default connect(mapStateToProps, { getGraduats })(GraduatedStudents);

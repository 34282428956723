import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    fill="none"
    stroke="#000"
    viewBox="0 0 24 24"
    {...props}
  >
    <g stroke="#212121" strokeLinecap="round" strokeWidth={2}>
      <path d="M4 18h16M4 12h16M4 6h16" />
    </g>
  </svg>
)
export default SvgComponent
import React, { useEffect, useState } from 'react';
// import triangle from '../../images/bucaq.jpg';
import RightArrowSvg from '../img/svg/RightArrow';
import DiplomsPopup from '../components/DiplomsPopup';
import { useDispatch, useSelector } from 'react-redux';
import { getContent } from '../actions/MainActions';
import PearsonLogo from '../img/PearsonLogo.png'

const Card29 = ({ title, description, text1, text2, btn_text }) => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const lang_id = useSelector(state => state.Data.lang_id);
  const dispatch = useDispatch();
  const content = useSelector(state => state.Data.content);
  useEffect(() => {
    dispatch(getContent(lang_id));
  }, [dispatch, lang_id]);

  return (
    <div className='flex flex-col mt-[20px] gap-[6px] w-1/2 max-[1120px]:w-full'>
      <div className='flex flex-col gap-[6px]'>
        <div className="banner">
          <div className='flex items-center justify-between'>
            <span className="text-[20px] font-[700] text-[#fff]">{title}</span>
            {/* <div className='w-[2px] h-[44px] bg-white'></div> */}
            {/* <div className='bg-white rounded-[8px]'>
              <img className='h-[54px]' src={PearsonLogo} alt='pearson logo'/>
            </div> */}
          </div>
          <span className="arrow"></span>
        </div>
      </div>

      <div className='flex flex-col gap-[24px]'>
        <div className='flex flex-col gap-[4px] mt-[20px]'>
          <span className='text-[#333] text-[22px] font-[700]'>{description}</span>
          <div className='flex flex-col gap-[8px]'>
            <p className='text-[17px] font-[400] text-[#212529]'>
              {text1}
            </p>
            <p className='text-[17px] font-[400] text-[#212529]'>
              {text2}
            </p>
          </div>
        </div>
      </div>

      <div>
        <button onClick={togglePopup} className='flex items-center gap-[3px] bg-[var(--blue)] text-[#fff] p-[15px_30px] rounded-[50px] text-[15px] font-[700]'>
          {btn_text}
          <RightArrowSvg fill='#fff' width='20' height='20' />
        </button>
        {showPopup && <DiplomsPopup show={showPopup} onClose={togglePopup} />}
      </div>
    </div>
  );
};

export default Card29;

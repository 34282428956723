import React, { useEffect } from 'react'
import SubjectCard from '../subjects/SubjectCard'
import { useDispatch, useSelector } from 'react-redux'
import { getSubjects } from '../../actions/MainActions'
import { useTranslation } from 'react-i18next'


const ALevelDiploma = () => {
  const lang_id = useSelector(state => state.Data.lang_id)
  const Subjects = useSelector(state => state.Data.Subjects || [])

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubjects(lang_id))
  }, [dispatch, lang_id])

  const {t} = useTranslation()

  return (
    <div className='grid grid-cols-4 gap-[20px] mt-[26px] container max-[1400px]:grid-cols-3 max-[990px]:grid-cols-2 max-[770px]:grid-cols-1 max-[770px]:place-items-center'>
      <SubjectCard
      subject={t("accouting")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717420710accImg2.891e6e1263c1d3653106.jpg"}
      />
      <SubjectCard
      subject={t("chemistry")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717420736chemistry.d6fb133598a650f39707.jpg"}
      />
      <SubjectCard
      subject={t("biology")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484599biology.7ea30f2b023b04ce5371.jpg"}
      />
      <SubjectCard
      subject={t("economics")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484624economics.91393cd76813880bd405.jpg"}
      />
      <SubjectCard
      subject={t("geography")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484646geography.4bee0dfcbc8780902280.jpg"}
      />
      <SubjectCard
      subject={t("information_technology")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484665IT.66ea7b7498bcc306f8ab.jpg"}
      />
      <SubjectCard
      subject={t("mathematics")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484709math.4d4ffe0d161cfe01a90d.jpg"}
      />
      <SubjectCard
      subject={t("physics")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484725physics.f5f7ec89c1033bbc6faf.jpg"}
      />
      <SubjectCard
      subject={t("business")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484745business2.c5a6a44113cd29e44b7f.jpg"}
      />
      <SubjectCard
      subject={t("english")}
      background={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/subjects/1717484758englang.71afa152e4df54ac7e12.jpg"}
      />
      {/* {
        Subjects.slice(0, 10).map(data => (
          <SubjectCard
          key={data.id}
          subject={data.translate.title}
          background={data.image_full_url}
          />
        ))
      } */}
    </div>
  )
}

export default ALevelDiploma
import React, { useState, useEffect } from 'react';
import ArrowUpSvg from '../img/svg/ArrowUpSvg';

const ScrollIcon = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className={`${isVisible ? 'block' : 'hidden'} fixed bottom-4 right-4 bg-[var(--orange)] p-[10px] cursor-pointer transition duration-500 ease-in-out z-50`}
      onClick={scrollToTop}
    >
      <ArrowUpSvg />
    </div>
  );
};

export default ScrollIcon;

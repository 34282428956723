import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const LoadMoreBtn = ({ count, defCount = 8, contentData, onLoadData }) => {
  const [page, setPage] = useState(1);
  const [prevData, setPrevData] = useState(0);
  const [loadMore, setLoadMore] = useState(true);
  const [showLess, setShowLess] = useState(false);

  const loadMoreBtn = () => {
    if (count !== prevData) {
      const newPage = page + 1;
      setPrevData(count);
      setPage(newPage);
      onLoadData(newPage);
    } else {
      if (count === defCount) {
        setLoadMore(false);
      } else {
        setShowLess(true);
      }
    }
  };

  const showLessBtn = () => {
    setPage(1);
    setPrevData(0);
    setLoadMore(true);
    setShowLess(false);
    onLoadData(1);
  };

  const {t} = useTranslation()

  return (
    <div className='flex justify-center items-center gap-[20px] mt-[20px]'>
      {count % defCount === 0 && loadMore && !showLess ? (
        <button 
          onClick={loadMoreBtn} 
          className='bg-[var(--blue)] px-[30px] h-[35px] text-white font-semibold rounded-[50px] space-grotesk'
        >
          {t("more")}
        </button>
      ) : null}
      {(count > defCount && count % defCount !== 0) || showLess ? (
        <button 
          onClick={showLessBtn} 
          className='bg-white px-[30px] h-[35px] txt-blue border-[2px] border-blue-800 font-semibold rounded-[50px]'
        >
          {t("less")}
        </button>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData,
});

export default connect(mapStateToProps)(LoadMoreBtn);

import React, { useEffect } from 'react'
import PageHeader from '../components/PageHeader'
import CentersParentCont from '../components/centers/CentersParentCont'
import { useDispatch, useSelector } from 'react-redux'
import { getContent } from '../actions/MainActions'
import FaqSection from '../components/FaqSection'
import { useTranslation } from 'react-i18next'

const CentersPage = () => {
  const lang = useSelector(state=> state.Data.lang)
  const lang_id = useSelector(state => state.Data.lang_id);
  const content = useSelector(state => state.Data.content);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContent(lang_id));
  }, [dispatch, lang_id]);

  const {t} = useTranslation()
  return (
    <div className='pt-[100px]'>
      <PageHeader page_title={t("nav_4")} page_title_2={content?.contact}/>
      <CentersParentCont/>
      {/* <FaqSection/> */}
    </div>
  )
}

export default CentersPage
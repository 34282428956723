import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CloseSvg from '../img/svg/CloseSvg';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import PhoneSvg4 from '../img/svg/PhoneSvg4';
import UserSvg1 from '../img/svg/UserSvg1';
import { getContent, postStatistics } from '../actions/MainActions';
import { changeStateValue } from '../redux/MainReducer';
import { connect, useDispatch, useSelector } from 'react-redux';


const DiplomsPopup = ({ show, onClose, children, postStatistics, changeStateValue }) => {
  const {t} = useTranslation()
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    education_type: null,
    company_id: '',
    subject: '',
    phone: '',
    contacsPage: null,
    errors: {},
    successMessage: '',
    style: 1,
    btnDisable: false
  });

  const { name, surname, education_type, phone, contacsPage, errors, successMessage, btnDisable } = formData;

  const onClickPageSchool = (index) => {
    let education_type = index === 1 ? 1 : 2;
    let school_type = '';
    switch (index) {
      case 3:
        school_type = 'private_school';
        break;
      case 4:
        school_type = 'secondary_school';
        break;
      case 5:
        school_type = 'primary_school';
        break;
      default:
        school_type = '';
    }
    setFormData({ ...formData, contacsPage: index, education_type, school_type });
  };

  const lang_id = useSelector(state => state.Data.lang_id);
  const dispatch = useDispatch();
  const content = useSelector(state => state.Data.content);
  useEffect(() => {
    dispatch(getContent(lang_id));
  }, [dispatch, lang_id]);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChangePhone = (phone) => {
    setFormData({ ...formData, phone });
  };

  const handleSubmit = () => {
    const { name, surname, education_type, phone, school_type } = formData;
    let errors = {};

    if (!name) {
      errors.name = `${t("name_error")}`;
    }
    if (!surname) {
      errors.surname = `${t("surname_error")}`;
    }
    if (education_type === null) {
      errors.education_type = `${t("school_error")}`;
    }
    if (!phone) {
      errors.phone = `${t("phone_error")}`;
    }
    if (Object.keys(errors).length > 0) {
      setFormData({ ...formData, errors });
      return;
    }

    const data = {
      name: name + ' ' + surname,
      education_type,
      school_type,
      phone_number: phone
    };

    setFormData({ ...formData, btnDisable: true });

    postStatistics(data)
      .then((resp) => {
        setFormData({
          name: '',
          surname: '',
          education_type: null,
          company_id: '',
          subject: '',
          phone: '',
          contacsPage: null,
          errors: {},
          successMessage: 'Form submitted successfully!',
          style: 1,
          btnDisable: false
        });

        if (resp === 'success') {
          changeStateValue({ name: 'popUpVisible', value: true });
          onClose();
        }
      })
      .catch((error) => {
        setFormData({ ...formData, successMessage: '', errors: { form: 'Form submission failed!' }, btnDisable: false });
      });
  };

  if (!show) {
    return null;
  }



  return (
    <div>
      <div className='popup-bg-thanks' onClick={onClose}>
        <div className='popup-thanks max-w-[578px] m-[16px] rounded-[8px]' onClick={(e) => e.stopPropagation()}>
          {children}
          <div className='flex justify-between border-b-[2px]'>
            <div></div>
            <div className='flex justify-between w-[68%]'>
              <h3 className='font-[600] text-[#032552] p-[22px_0px] text-[24px] text-center max-w-[500px]'>
                {t("anket")}
              </h3>
              <div className='flex items-start '>
                <button onClick={onClose} className='m-[7px]'>
                  <CloseSvg />
                </button>
              </div>
            </div>
          </div>

          <div className='p-[12px] grid grid-cols-2 gap-[20px]'>
            <div className='flex flex-col gap-[8px]'>
              <div className='flex items-center gap-[5px]'>
                <UserSvg1 />
                <span className='text-[14px] font-[550] text-[#000]'>{t("name")}</span>
                {errors.name && <span className='text-red-500 text-[12px]'>{errors.name}</span>}
              </div>
              <div className='flex flex-col'>
                <div className='flex items-center border-[1px] rounded-[8px] p-[10px]'>
                  <input
                    className=' w-full space-grotesk'
                    placeholder={t("name")}
                    name='name'
                    value={name}
                    onChange={handleChange}
                    id='name'
                  />
                </div>
              </div>
            </div>

            <div className='flex flex-col gap-[8px]'>
              <div className='flex items-center gap-[5px]'>
                <UserSvg1 />
                <span className='text-[14px] font-[550] text-[#000]'>{t("surname")}</span>
                {errors.surname && <span className='text-red-500 text-[12px]'>{errors.surname}</span>}
              </div>
              <div className='flex flex-col'>
                <div className='flex items-center border-[1px] rounded-[8px] p-[10px]'>
                  <input
                    className=' w-full space-grotesk'
                    placeholder={t("surname")}
                    name='surname'
                    value={surname}
                    onChange={handleChange}
                    id='surname'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='p-[0px_12px] flex flex-col gap-[10px]'>
            <div className='flex items-center gap-[5px]'>
              <span className='text-[#072465] text-[14px] font-[600]'>{t("which_school")}</span>
              {errors.education_type && <span className='text-red-500 text-[12px]'>{errors.education_type}</span>}
            </div>
            <div className='grid grid-cols-3 gap-[8px] w-full max-[460px]:grid-cols-1'>
              <button
                id='school1'
                onClick={() => onClickPageSchool(3)}
                className={`${
                  contacsPage === 3 ? 'text-white bg-[#264EA9]' : 'text-[#072465] border-[#92B3FF]'
                } p-[8px_10px] rounded-[50px] border-[1px] text-[15px] font-[500]`}>
                {/* {content?.high_school} (9-11) */} High School (9-11)
              </button>
              <button
                id='school2'
                onClick={() => onClickPageSchool(4)}
                className={`${
                  contacsPage === 4 ? 'text-white bg-[#264EA9]' : 'text-[#072465] border-[#92B3FF]'
                } p-[8px_10px] rounded-[50px] border-[1px] text-[15px] font-[500]`}>
                {/* {content?.secondary_school} (6-8) */} Secondary School (6-8)
              </button>
              <button
                id='school3'
                onClick={() => onClickPageSchool(5)}
                className={`${
                  contacsPage === 5 ? 'text-white bg-[#264EA9]' : 'text-[#072465] border-[#92B3FF]'
                } p-[8px_10px] rounded-[50px] border-[1px] text-[15px] font-[500]`}>
                {/* {content?.primary_school} (1-5) */} Primary School (1-5)
              </button>
            </div>
          </div>

          <div className='p-[0px_12px] mt-[15px] gap-[24px]'>
            <div className='flex flex-col w-full gap-[5px] '>
              <div className='flex flex-col gap-[5px]'>
                <div className='flex items-center gap-[5px]'>
                  <PhoneSvg4 />
                  <span className='text-[14px] font-[550] text-[#000]'>{t("phone")}</span>
                </div>
                {errors.phone && <span className='text-red-500 text-[12px]'>{errors.phone}</span>}
              </div>
              <div className='border-[1px] w-full rounded-[8px] p-[3px]'>
                <PhoneInput
                  containerStyle={{
                    border: 'none',
                    margin: '0'
                  }}
                  inputStyle={{
                    backgroundColor: '#f2f2f2',
                    padding: '0px',
                    marginLeft: '38px',
                    borderRadius: '5px',
                    border: 'none',
                    background: 'none'
                  }}
                  buttonStyle={{
                    backgroundColor: 'transparent',
                    border: 'none'
                  }}
                  dropdownStyle={{
                    borderRadius: '0px'
                  }}
                  country={'az'}
                  value={phone}
                  onChange={handleChangePhone}
                  className='custom-flag'
                />
              </div>
            </div>
          </div>

          <div>
            <div className='flex justify-center w-full p-[0px_12px] mt-[32px] mb-[20px]'>
              <button
                disabled={btnDisable}
                onClick={handleSubmit}
                className='bg-[#EF7000] w-full p-[12px_0px] text-white rounded-[8px] text-[15px] font-[400]'>
                {/* {content?.get_info} */}
                {t("get_info")}
              </button>
            </div>
            {successMessage && <div className='text-green-500 text-center mb-[20px]'>{successMessage}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

DiplomsPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  postStatistics: PropTypes.func.isRequired,
  changeStateValue: PropTypes.func.isRequired
};

export default connect(null, { postStatistics, changeStateValue })(DiplomsPopup);

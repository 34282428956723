import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import SectionHeader from '../SectionHeader'
import Card19 from '../Card19'
import Card13 from '../Card13'
import Card29 from '../Card29'
import { useDispatch, useSelector } from 'react-redux';
import { getContent, getGraduats, getIntDiploma, getStudents } from '../../actions/MainActions';
import GraduatedStudents from '../students/GraduatedStudents';
import GraduatesHomeStudents from './GraduatesHomeStudents';
import { useTranslation } from 'react-i18next';

const SingleSchools = () => {
  const [graduatsData, setGraduatsData] = useState([]);
  const [kidsEnglishTypeData, setKidsEnglishTypeData] = useState([]);
  const [contentData, setContentData] = useState({});
  const [faqs, setFaqs] = useState([]);
  const schoolType = 1; 
  // Diplomas
  const lang_id = useSelector(state => state.Data.lang_id);
  const internationalDiploma = useSelector(state => state.Data.internationalDiploma);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getIntDiploma(lang_id));
  }, [dispatch, lang_id]);
  //Content
  const content = useSelector(state => state.Data.content);
  useEffect(() => {
    dispatch(getContent(lang_id));
  }, [dispatch, lang_id]);

  const {t} = useTranslation()

  return (
    <div className=' mt-0'>
      {schoolType === 1 && (

        <section className='container flex items-start gap-[32px] min-h-[500px] max-[1120px]:flex-col max-[1120px]:items-center  '>

        <div className='embed_header_video h-full  mt-[10px] w-1/2 max-[1120px]:w-full ' >
          <iframe 
            src="https://www.youtube.com/embed/COaJU62GF6M?si=bzZ52Hrfl-spAF-F" 
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen>
          </iframe>

        </div>

        <Card29 
            title={t("main_title")}
            description={t("home_title")}
            text1={t("home_description_1")}
            text2={t("home_description_2")}
            btn_text={t("get_info")}
          />
              </section>
      )}
        <section className='bg-[#f5f7f7] mt-[52px] pb-[52px]'>
          <div className='container'>
            <SectionHeader section_header_title={t("diploma_choice")}/>
            <div className='grid grid-cols-3 gap-[32px] max-[1080px]:grid-cols-2 max-[690px]:grid-cols-1 max-[420px]:gap-[50px]'>
              <Card19
              image={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/international_diplomas/172007957117181876461718009746ingiltere_flag.png"}
              lvl_diploma={t("a_level_diploma_title")}
              a_level_text_1={t("a_level_text_1")}
              a_level_text_2={t("a_level_text_2")}
              a_level_text_3={t("a_level_text_3")}
              a_level_text_4={t("a_level_text_4")}
              a_level_text_5={t("a_level_text_5")}
              />
              <Card19
              image={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/international_diplomas/17200796821718009800canada_flag%20(1).png"}
              lvl_diploma={t("high_school_diploma")}
              a_level_text_1={t("high_school_text_1")}
              a_level_text_2={t("high_school_text_2")}
              a_level_text_3={t("high_school_text_3")}
              a_level_text_4={t("high_school_text_4")}
              a_level_text_5={t("high_school_text_5")}
              />
              <Card19
              image={"https://phplaravel-991035-4684557.cloudwaysapps.com/storage/uploads/international_diplomas/17200797631718009813amerika_flag%20(1).webp"}
              lvl_diploma={t("ap_diploma")}
              a_level_text_1={t("ap_diploma_text_1")}
              a_level_text_2={t("ap_diploma_text_2")}
              a_level_text_3={t("ap_diploma_text_3")}
              a_level_text_4={t("ap_diploma_text_4")}
              a_level_text_5={t("ap_diploma_text_5")}
              />
              {/* {internationalDiploma.map((data, i) => (
                <Card19 
                  key={data?.id}
                  image={data?.image_full_url}
                  lvl_diploma={data?.translate?.title}
                  text={data?.translate?.text}
                />
              ))} */}
            </div>
          </div>
        </section>
        <section>
        <SectionHeader section_header_title={t("graduates")}/>
        <GraduatesHomeStudents/>
          <div className='flex justify-center mt-[12px]'>
            <Link to='/graduates'>
              <button className='text-[var(--blue)] p-[6px_16px] text-[16px] font-[600] border-[var(--blue)] rounded-[20px] border-[1px]'>
                {t("view_all")}
                {/* {content?.btn_view_all} */}
              </button>
            </Link>
          </div>
        </section>
    </div>
  )
}

export default SingleSchools;

import React, { useEffect } from 'react';
// import successImage from '../images/successImage.png';
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';

const SuccessPopup = ({ contentData, changeStateValue }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      changeStateValue({ name: "popUpVisible", value: false });
    }, 2000);
    
    // Cleanup function to clear timeout if component unmounts
    return () => clearTimeout(timeout);
  }, []); // Empty dependency array to run effect only once on mount

  return (
    <div className='success-popup'>
      <div className='success-popup-content'>
        {/* <p className='bg-[red]'>Tewekkürlər! Qeydiyyatınız uğurla tamamlandı.</p> */}
        <div className='p-[40px] '>
          <div className='flex justify-center'>
            {/* <img src={successImage} className='w-[120px] h-[120px]' alt="Success Icon" /> */}
          </div>

          <div className='flex flex-col gap-[8px] mt-[32px]'>
            <span className='font-[600] text-[17px] text-[#333] text-center'>{contentData?.request_has_been_accepted}</span>
            <span className='font-[600] text-[17px] text-[#333] text-center'>{contentData?.will_call_you}</span>
            <span className='font-[600] text-[17px] text-[#333] text-center'>{contentData?.number_verification}</span>
            <span className='font-[600] text-[17px] text-[#333] text-center'>{contentData?.enter_your_number}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData,
  lang_id: state.Data.lang_id,
});

const mapDispatchToProps = { changeStateValue };

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPopup);

import React, { useEffect } from 'react'
import HomeHeader from '../components/home/HomeHeader'
import SectionHeader from '../components/SectionHeader'
import Students from '../components/home/Students'
import DiplomaParent from '../components/home/DiplomaParent'
import OptionsParent from '../components/home/OptionsParent'
import ProgramsParent from '../components/home/ProgramsParent'
import Subjects from '../components/home/Subjects'
import SubjectsBg from '../img/SubjectsBg.jpg'
import NextStepBg from '../img/NextStepBg.jpg'
import Comment from '../components/home/Comment'
import { useDispatch, useSelector } from 'react-redux'
import { getContent } from '../actions/MainActions'
import StudentSlider from '../components/home/StudentSlider'
import PopupComponent from '../components/Popup'
import FaqSection from '../components/FaqSection'
import SingleSchools from '../components/home/SingleSchools'
import StudentReviews from '../components/StudentReviews'
import { useTranslation } from 'react-i18next'
import WhatsAppButton from '../components/btn/WhatsAppButton'
import PhoneButton from '../components/btn/PhoneButton'


const HomePage = () => {
  const lang_id = useSelector(state => state.Data.lang_id);
  const content = useSelector(state => state.Data.content);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContent(lang_id));
  }, [dispatch, lang_id]);

  const data = content || {};
  // console.log(content)

  const {t} = useTranslation()
  return (
    <div className='pt-[152px]'>
      <div className='mb-[52px] flex gap-[18px] p-[0_34%] pt-[16px] max-[1350px]:p-[0_29%] max-[1340px]:p-[0_26%] max-[910px]:p-[0_24%] max-[855px]:p-[0_20%] max-[735px]:p-[0_16%] max-[642px]:p-[0_10%] max-[555px]:container'>
        <PhoneButton
        text={t("call")}
        phoneNumber={"050 371 3434"}
        />
        <WhatsAppButton
        text={t("message")}
        url={"https://wa.me/+994503713434"}
        />
        </div>
    {/* <SectionHeader section_header_title={t("information_title")}/> */}
    <SingleSchools/>
    <SectionHeader section_header_title={t("why_celt_school")}/>
    {/* content?.why_celt_school */}
    <OptionsParent/>
    <SectionHeader section_header_title={t("reviews")}/>
    <StudentReviews/>
    </div>
  )
}

export default HomePage
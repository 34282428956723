import React, { Component } from 'react'

export class CloseSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"

  >
    <circle cx={15} cy={15} r={15} fill="#fff" />
    <path
      fill="#4F46E5"
      d="m19.012 11.512-3.253 3.254 3.253 3.253a.702.702 0 1 1-.993.994l-3.253-3.254-3.254 3.253a.703.703 0 0 1-.993-.993l3.253-3.253-3.253-3.254a.703.703 0 0 1 .993-.993l3.254 3.253 3.253-3.253a.703.703 0 0 1 .994.993Zm7.707 3.254A11.953 11.953 0 1 1 14.766 2.812a11.967 11.967 0 0 1 11.953 11.954Zm-1.407 0a10.547 10.547 0 1 0-10.546 10.546 10.559 10.559 0 0 0 10.546-10.546Z"
    />
  </svg>
    )
  }
}

export default CloseSvg
import React, { useEffect, useState } from 'react';
import GraduationSvg from '../img/svg/GraduationSvg';
import Location from '../img/svg/Location2Svg';
import DiplomaSvg from '../img/svg/DiplomaSvg';
import { Link } from 'react-router-dom';
import University from '../img/svg/UniversitySvg';
import rank1 from "../img/vecteezy_modern-top-chart-badges_163682 [Converted] copy-101.png";
import rank2 from "../img/vecteezy_modern-top-chart-badges_163682 [Converted] copy-102.png";
import rank3 from "../img/vecteezy_modern-top-chart-badges_163682 [Converted] copy-103.png";
import rank4 from "../img/vecteezy_modern-top-chart-badges_163682 [Converted] copy-104.png";
import { connect, useDispatch, useSelector } from 'react-redux';
import { getContent } from '../actions/MainActions';

const Card13 = ({
  first_name,
  image_full_url,
  user_image = "",
  last_name,
  company_name,
  country_image,
  country_name,
  program_name,
  inst_name,
  ranking = "",
  grade,
  company_id,
  contentData,
}) => {

  const [randomImage, setRandomImage] = useState(null);

  useEffect(() => {
    const images = [rank1, rank2, rank3, rank4];
    const randomIndex = Math.floor(Math.random() * 4);
    setRandomImage(images[randomIndex]);
  }, []);

  const lang_id = useSelector(state => state.Data.lang_id);
  const dispatch = useDispatch();
  const content = useSelector(state => state.Data.content);
  useEffect(() => {
    dispatch(getContent(lang_id));
  }, [dispatch, lang_id]);

  const getNameText = (first_name, last_name) => {
    const firstInitial = first_name ? first_name.charAt(0) : '';
    const lastInitial = last_name ? last_name.charAt(0) : '';
    return firstInitial + lastInitial;
  };

  const getName = (name) => {
    const splt = name.split(' ');
    let txt = '';
    if (splt.length > 0) {
      txt += splt[0][0].toUpperCase();
      if (splt.length > 1) {
        txt += splt[splt.length - 1][0].toUpperCase();
      }
    }
    return txt;
  };

  return (
    <div className="rounded-[8px_8px_8px_8px] pb-[8px] bg-[#fff] shadow m-[10px] relative">
      <div className="flex justify-end mr-[15px] rounded-[8px_8px_0px_0px] absolute z-40 w-full">
        {ranking <= 500 ? (
          <div className='relative flex justify-center items-center mr-[10px] mt-[10px]'>
            <img style={{ width: '50px' }} src={randomImage} alt={`Rank ${ranking}`} />
            <span className='absolute text-[20px] text-white font-black mt-[9px] different-font'>{ranking}</span>
          </div>
        ) : null}
      </div>

      <div className="background-image-cont relative">
        <img className="w-full h-full rounded-[8px_8px_0_0]" src={image_full_url} alt="Student" />
      </div>

      <div className="p-[0_15 px_15px_15px]">
        <div className="pl-[10px] flex flex-col gap-[5px] w-297 clip-custom bg-[var(--white)] rounded-[0px_0px_8px_8px]">
          {user_image !== "" ? (
            <img
              className="absolute w-[80px] h-[80px] rounded-[50px] border-[4px] border-white mt-[-90px]"
              src={user_image}
              alt="User"
            />
          ) : (
            <span className="w-[80px] h-[80px] rounded-[50px] inline-flex justify-center items-center text-white text-[27px] font-[500] bg-blue-700">
              {getNameText(first_name, last_name)}
            </span>
          )}
          <div className="p-[8px] flex flex-col gap-[20px]">
            <div className="flex items-center gap-[4px]">
              <h3 className="font-[700] text-[18px] text-[var(--blue)]">{getName(first_name)}.</h3>
              <h3 className="font-[700] text-[18px] text-[var(--blue)]">{last_name}</h3>
            </div>
            <section>
              <div className="flex flex-col gap-[8px] border-b-[1px] pb-[15px]">
                <p className="text-[14px] font-[700] text-[#000]">{inst_name}</p>
                <div className="flex flex-col gap-[6px] ml-[5px]">
                  <div className="flex items-center gap-[12px] ml-[-3px]">
                    <GraduationSvg />
                    <span className="text-[12px] font-[500] text-[black]">{program_name}</span>
                  </div>
                  <div className="flex items-center gap-[16px]">
                    <img className="w-[20px] h-[12px]" src={country_image} alt="Country" />
                    <span className="text-[12px] font-[500] text-[black]">{country_name}</span>
                  </div>
                  <div className="flex items-end gap-[16px] ml-[0px]">
                    <University />
                    <span className="text-[12px] font-[500] text-[black]">{content?.card_acceptance}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[6px] pt-[15px]">
                <div>
                  <p className="text-[14px] font-[700] text-[#000] mb-[6px]">{content?.card_diploma}</p>
                </div>
                <div className="ml-[4px] flex flex-col gap-[6px]">
                  <div className="flex items-center gap-[12px]">
                    <div>
                      <DiplomaSvg />
                    </div>
                    <span className="text-[12px] font-[500] text-[black]">{content?.high_school}</span>
                    <div className="flex items-center gap-[6px]">
                      <span className="text-[12px] font-[600] text-[var(--blue)]">{content?.gpa}</span>
                      <span className="text-[12px] font-[500] text-[black]">{grade}</span>
                    </div>
                  </div>
                  <div className="flex items-center  h-[20px] gap-[10px] ml-[-2px]">
                    <div className='flex items-center'>
                      <Location width="24" height="24" fill="var(--blue)" />
                    </div>
                    <p className="text-[#0066FF] flex items-center h-full text-[14px] font-[500]">
                      {company_name}
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData,
  lang_id: state.Data.lang_id,
});

export default connect(mapStateToProps)(Card13);

export const azlang = {
    nav_1:'ANA SƏHIFƏ',
    nav_2:'FƏNNLƏR',
    nav_3:'TƏLƏBƏLƏR',
    nav_4:'KAMPUS ÜNVANLARI',
    home_title:'Dünyanın ən güclü universitetlərinə gedən yol',
    home_description:'CELT A Level diplomu ilə siz dünyanın ən güclü uniersitetlərinə asan qəbul ola və təhsil ala bilərsiniz. Bizim individual yanaşmamız, sizin bütün sahələrdə uğurlu nəticələr əldə etmənizə zəmanət verir. Siz CELT məktəblərində övladlarınızı dünya standardlarına uyğun yetişdirə bilərsiniz.',
    home_button: 'Uğura aparan yolu seçin',
    graduates: 'MƏZUNLARIMIZ',
    graduates_button:'Bütün tələbələrə baxın',
    foreign_school_diplomas:'BEYƏLXALQ MƏKTƏB DIPLOMLARI',
    a_level_diploma_title:'A level Diplomu',
    a_level_diploma_description_1:'Oxford və ya Cambridge kimi universitetlər üçün A Level diplomu mütləqdir.',
    a_level_diploma_description_2:'Ama bundan əlavə sizə Oxford imtahanı lazımdır. Biz Oxford Universitetinin rəsmi qəbul imtahanı mərkəziyik.',
    a_level_diploma_description_3:'Bizim özəl top universitetlərə qəbul hazırlığımız sizin uğurlu olmanızda önəmli rol oynayır.',
    hs_diploma_description_1:'HS diplomu sizə dünyanın bir çox universitetinə rahat qəbul olmanıza kömək edir.',
    hs_diploma_description_2:'HS diplomu alanlar çox zaman SAT və ya IELTS imtahanı almasına ehtiyac olmur və ya nəticə almaq çətin də olmur. Biz HS diplomu ilə bir başa qəbul olmağa hazırlayırıq.',
    hs_diploma_description_3:'HS diplomunu 1 və ya 2 ilə bitirə bilərsiniz. Sizin ayırdığınız zamandan asılıdır.',
    celt_campus_button:'CELT məktəbini seç',
    why_celt_school:'NIYƏ CELT HIGH SCHOOL?',
    international_teachers_title:'Beynəlxalq dərəcəli müəllimlər',
    international_teachers_description_1:'Müəllimlərimizin ixtisas üzrə dərəcələri var.',
    international_teachers_description_2:'Müəllimlərin hədəfi tələbəni məqsədinə çatdırmaqdır.',
    international_teachers_description_3:'Müəllim hamısı yalnız ingilis dilində tədris edir.',
    individual_approach_title:'İndividual yanaşma',
    individual_approach_description_1:'Hər bir tələbəmizin hər dərsi raporlarnır və baş müəllimlər tərəfindən təhlil edilir.',
    individual_approach_description_2:'Rəhbərlərimiz hər zaman tələbələrimizə xüsusi diqqət ayıraraq onların davamlı və disiplinli oxumasına kömək edir.',
    individual_approach_description_3:'Tələbələrimiz hər mövzunu öyrəndikcə həm praktiki imtahanlar verirlər həm də hər mövzunun sonunda ona aid tam imtahan verirlər. Bu da bizə uğurla nəticəyə doğru etibarlı şəkildə addımlamağa icazə verir.',
    vip_classes_title:'VIP siniflər',
    vip_classes_description_1:'Siniflərimiz VIP formatındadır. Hər sinifdə 2-3 tələbə olur. Müəllim bütün diqqətini tələbələrinin problemlərinin həll olunmasına ayrırı. Bu da daha yaxşı nəticələrə gətirirb çıxarır.',
    period_of_programs_title:'Proqramların bitirmə müddəti',
    period_of_programs_option_1:'2 illik standard proqram',
    period_of_programs_option_2:'1 illik sürətli proqram',
    period_of_programs_option_3:'3 illik rahat proqram',
    period_of_programs_description:'Əgər başqa məktəbdə A level səviyyəli və ya ekvivalentinin birinci ilini bitirmisinizsə, yalnız son A2 ili üçün proqrama qoşula bilərsiniz.',
    choise_of_subjects_title:'Geniş fənn seçimi',
    choise_of_subjects_description_1:'Sevdiyiniz sahəni oxuyun.',
    choise_of_subjects_description_2:'Biz iqtisadiyyat, dəqiq elmlər, mühəndislik, tibb və hümanitar sahələr üzrə fənlər tədris edirik. We offer a wide range of subjects across sciences, arts and humanities.',
    choise_of_subjects_description_3:'Nəzərdə tutulan dərəcəniz üçün ən uyğun olanları seçməyinizə kömək edəcəyik.',
    choise_of_subjects_button:'İxtisasınıza uyğun fənnlərə baxın',
    student_title:'"CELT School diploma mənə universitetə bir başa qəbul olmağıma və burda çox asan oxumağına böyük dəstək oldu. Bakıda oxuduğum dərslərin çoxunu burda təkrarlayıram. Təşəkkürlər hər kəsə. "',
    student_name:'Aişə',
    student_university:'Groningen University',
    application_title:'Növbəti addımı atın: Elə indi müraciət edin',
    application_description_1:'Məktəbimizdə oxumaq üçün müraciət sadə və sürətlidir.',
    application_description_2:'Sizə yaxın olan kampusu seçirsiniz və əlaqə saxlayırsınız.',
    application_description_3:'Məktəbimizdə oxumaq üçün hazırkı bilikləriniz yetərlidirsə, sizə ən qısa zamanda dəvət göndəririk..',
    application_button:'Müraciət edin',
    subjects:'FƏNNLƏR',
    home_page:'Ana səhifə',
    our_courses:'Bizim kurslarımız',
    accouting:'Mühasibat uçotu',
    biology:'Biologiya',
    chemistry:'Kimya',
    economics:'İqtisadiyyat',
    geography:'Coğrafiya',
    information_technology:'İnformasiya texnologiyaları',
    mathematics:'Riyaziyyat',
    physics:'Fizika',
    business:'Biznes',
    english:'Ingilis dili',
    sign_up:'Qeydiyyat ol',
    hs_diploma_title:'HS Diplomu',
    financial_accounting:'Maliyyə Uçotu Prinsipləri (M)',
    international_business:'Beynəlxalq Biznes Əsasları (M)',
    business_leadership:'Biznes Liderliyi (M)',
    enviroment_resource:'Ətraf Mühit Resurslarının İdarə Edilməsi (M)',
    canadian_world_issues:'Kanada və Dünya Problemləri (U)',
    analysing_current:'Cari İqtisadi Problemlərin Təhlili (U)',
    canadian_international_law:'Kanada və Beynəlxalq Hüquq (U)',
    english_u:'İngilis (U)',
    nutrition_health:'Qidalanma və Sağlamlıq (U)',
    canada_families:'Kanadadakı ailələr (U)',
    fashion:'Moda (M)',
    change_society:'Cəmiyyətdə Çağırış və Dəyişiklik (U)',
    computer_science:'Kompüter Elmləri (U)',
    calculus_vectors:'Hesablama və Vektorlar (U)',
    advanced_functions:'Qabaqcıl funksiyalar (U)',
    biology_u:'Biologiya (U)',
    physics_u:'Fizika (U)',
    chemistry_u:'Kimya (U)',
    communications_technology:'Rabitə Texnologiyası (M)',
    students_title:'TƏLƏBƏLƏR',
    our_graduates:'Bizim məzunlarımız',
    campus_addresses:'MƏKTƏBLƏRİMİZ',
    contact:'Əlaqə',
    secondary_school:'Beynəlxalq Orta Məktəb',
    primary_classes:'Beynəlxalq İbtidai Siniflər',
    call:'Zəng et',
    message:'Mesaj yaz',
    main_title:'CELT High School',
    student_btn_1: 'Daha çox',
    student_btn_2: 'Daha az',
    narimanov_statue: 'Nərimanov Heykəli',
    bilgah: 'Bilgəh'


}

export const enlang = {
    nav_1:'HOME PAGE',
    nav_2:'SUBJECTS',
    nav_3:'STUDENTS',
    nav_4:'CAMPUS ADDRESSES',
    home_title:'The road to the world is most powerful universities',
    home_description:'With a CELT A Level diploma, you can easily get admission and study at the world is most powerful universities. Our individual approach guarantees you successful results in all areas. You can educate your children according to world standards in CELT schools.',
    home_button: 'Choose the path that leads to success',
    graduates: 'OUR GRADUATES',
    graduates_button:'View all students',
    foreign_school_diplomas:'FOREIGN SCHOOL DIPLOMAS',
    a_level_diploma_title:'A level Diploma',
    a_level_diploma_description_1:'An A Level diploma is a must for universities such as Oxford or Cambridge.',
    a_level_diploma_description_2:'But in addition you need the Oxford exam. We are the official entrance exam center for the University of Oxford.',
    a_level_diploma_description_3:'Our top private university admission preparation plays an important role in your success.',
    hs_diploma_description_1:'The HS diploma helps you get easily accepted into many universities around the world.',
    hs_diploma_description_2:'HS diploma recipients often do not need to take the SAT or IELTS, or it is not difficult to get the results. We prepare for admission with a HS diploma.',
    hs_diploma_description_3:'You can complete your HS diploma in 1 or 2 years. It depends on your spare time.',
    celt_campus_button:'Choose a CELT Campus',
    why_celt_school:'WHY CELT HIGH SCHOOL?',
    international_teachers_title:'International class teachers',
    international_teachers_description_1:'Our teachers have professional degrees.',
    international_teachers_description_2:'The goal of the teachers is to bring the student to his goal.',
    international_teachers_description_3:'All teachers teach only in English.',
    individual_approach_title:'Individual approach',
    individual_approach_description_1:'Every lesson of each of our students is reported and analyzed by head teachers.',
    individual_approach_description_2:'Our leaders always pay special attention to our students and help them study continuously and disciplined.',
    individual_approach_description_3:'As our students study each topic, they take both practical exams and at the end of each topic, they take a full exam related to it. This allows us to confidently move towards a successful outcome.',
    vip_classes_title:'VIP classes',
    vip_classes_description_1:'Our classes are in VIP format. There are 2-3 students in each class. The teacher devotes all his attention to solving the problems of his students. This leads to better results.',
    period_of_programs_title:'Completion period of programs',
    period_of_programs_option_1:'2-year standard program',
    period_of_programs_option_2:'1-year fast track program',
    period_of_programs_option_3:'A convenient 3-year program',
    period_of_programs_description:'If you have completed the first year of A level or equivalent at another school, you can only join the program for the final A2 year.',
    choise_of_subjects_title:'Wide choice of subjects',
    choise_of_subjects_description_1:'Study your favorite field.',
    choise_of_subjects_description_2:'We teach subjects in economics, exact sciences, engineering, medicine and humanities. We offer a wide range of subjects across sciences, arts and humanities.',
    choise_of_subjects_description_3:'We will help you choose the best ones for your intended degree.',
    choise_of_subjects_button:'Look for subjects that match your major',
    student_title:'"The CELT School diploma was a great support for me to get accepted to the university and it was very easy to study here. I repeat most of the lessons I studied in Baku here. Thanks everyone."',
    student_name:'Aisha',
    student_university:'Groningen University',
    application_title:'Take the next step: Apply now',
    application_description_1:'Applying to study at our school is simple and fast.',
    application_description_2:'You choose the campus closest to you and stay connected.',
    application_description_3:'If your current knowledge is sufficient to study at our school, we will send you an invitation as soon as possible.',
    application_button:'Apply now',
    subjects:'SUBJECTS',
    home_page:'Home page',
    our_courses:'Our courses',
    accouting:'Accounting',
    biology:'Biology',
    chemistry:'Chemistry',
    economics:'Economy',
    geography:'Geography',
    information_technology:'Information technologies',
    mathematics:'Mathematics',
    physics:'Physics',
    business:'Business',
    english:'English language',
    sign_up:'Sign up',
    hs_diploma:'HS Diploma',
    financial_accounting:'Financial Accounting Principles (M)',
    international_business:'International Business Fundamentals (M)',
    business_leadership:'Business Leadership (M)',
    enviroment_resource:'Environment Resource Management (M)',
    canadian_world_issues:'Canadian and World Issues (U)',
    analysing_current:'Analysing Current Economic Issues (U)',
    canadian_international_law:'Canadian and International Law (U)',
    english:'English (U)',
    nutrition_health:'Nutrition and Health (U)',
    canada_families:'Families in Canada (U)',
    fashion:'Fashion (M)',
    change_society:'Challenge and Change in Society (U)',
    computer_science:'Computer Science (U)',
    calculus_vectors:'Calculus and Vectors (U)',
    advanced_functions:'Advanced Functions (U)',
    biology_u:'Biology (U)',
    physics_u:'Physics (U)',
    chemistry_u:'Chemistry (U)',
    communications_technology:'Communications Technology (M)',
    students_title:'STUDENTS',
    our_graduates:'Our graduates',
    campus_addresses:'OUR SCHOOLS',
    contact:'Contact',
    secondary_school:'International Secondary School',
    primary_classes:'International Primary Classes',
    call:'Call',
    message:'Write a message',
    main_title:'CELT High School',
    hs_diploma_title:'HS Diploma',
    student_btn_1: 'More',
    student_btn_2: 'Less',
    narimanov_statue: 'Narimanov Statue',
    bilgah: 'Bilgah'
}

export const rulang = {
    nav_1: 'БЕРЕНДЕ',
    nav_2: 'ДИСЦИПЛИНЫ',
    nav_3: 'УЧЕНИКИ',
    nav_4: 'АДРЕСА КАМПУСОВ',
    home_title: 'Путь к мировым ведущим университетам',
    home_description: 'С дипломом CELT A Level вы сможете легко поступить и учиться в самых мощных университетах мира. Наш индивидуальный подход гарантирует успешные результаты во всех областях. Вы можете обучать своих детей в соответствии с мировыми стандартами в школах CELT.',
    home_button: 'Выберите путь к успеху',
    graduates: 'НАШИ ВЫПУСКНИКИ',
    graduates_button: 'Посмотреть всех студентов',
    foreign_school_diplomas: 'ДИПЛОМЫ ИНОСТРАННЫХ ШКОЛ',
    a_level_diploma_title: 'Диплом A Level',
    a_level_diploma_description_1: 'Диплом A Level необходим для университетов, таких как Оксфорд или Кембридж.',
    a_level_diploma_description_2: 'Но дополнительно вам потребуется экзамен Оксфорда. Мы являемся официальным центром входных экзаменов для Университета Оксфорда.',
    a_level_diploma_description_3: 'Наша подготовка к поступлению в топовые частные университеты играет важную роль в вашем успехе.',
    hs_diploma_description_1: 'Диплом HS помогает вам легко поступить во многие университеты по всему миру.',
    hs_diploma_description_2: 'Получатели диплома HS часто не нуждаются в сдаче SAT или IELTS, или им легко получить результаты. Мы готовим к поступлению с дипломом HS.',
    hs_diploma_description_3: 'Вы можете завершить диплом HS за 1 или 2 года. Это зависит от вашего свободного времени.',
    celt_campus_button: 'Выбрать кампус CELT',
    why_celt_school: 'ПОЧЕМУ СРЕДНЯЯ ШКОЛА CELT?',
    international_teachers_title: 'Международные преподаватели',
    international_teachers_description_1: 'Наши преподаватели имеют профессиональные степени.',
    international_teachers_description_2: 'Цель преподавателей - привести ученика к его цели.',
    international_teachers_description_3: 'Все преподаватели ведут занятия только на английском.',
    individual_approach_title: 'Индивидуальный подход',
    individual_approach_description_1: 'Каждое занятие каждого нашего студента фиксируется и анализируется старшими преподавателями.',
    individual_approach_description_2: 'Наши руководители всегда уделяют особое внимание нашим ученикам и помогают им учиться последовательно и дисциплинированно.',
    individual_approach_description_3: 'По мере изучения каждой темы наши студенты сдают как практические экзамены, так и итоговые экзамены, связанные с ней. Это позволяет нам уверенно двигаться к успешному результату.',
    vip_classes_title: 'VIP занятия',
    vip_classes_description_1: 'Наши занятия проводятся в формате VIP. В каждой группе 2-3 студента. Преподаватель уделяет все свое внимание решению проблем студентов, что приводит к лучшим результатам.',
    period_of_programs_title: 'Сроки завершения программ',
    period_of_programs_option_1: 'Стандартная программа на 2 года',
    period_of_programs_option_2: 'Ускоренная программа на 1 год',
    period_of_programs_option_3: 'Удобная 3-летняя программа',
    period_of_programs_description: 'Если вы завершили первый год A Level или эквивалент в другой школе, вы можете присоединиться только к программе для итогового года A2.',
    choise_of_subjects_title: 'Широкий выбор предметов',
    choise_of_subjects_description_1: 'Изучайте любимую область.',
    choise_of_subjects_description_2: 'Мы преподавали предметы в области экономики, точных наук, инженерии, медицины и гуманитарных наук. Мы предлагаем широкий выбор предметов в области науки, искусства и гуманитарных наук.',
    choise_of_subjects_description_3: 'Мы поможем вам выбрать лучшие для вашей предполагаемой специальности.',
    choise_of_subjects_button: 'Искать предметы, соответствующие вашей специальности',
    student_title: '"Диплом школы CELT был для меня большой поддержкой для поступления в университет, и учиться здесь было очень легко. Я повторяю большинство уроков, которые учил в Баку. Спасибо всем."',
    student_name: 'Аиша',
    student_university: 'Университет Гронинген',
    application_title: 'Сделайте следующий шаг: Подайте заявку сейчас',
    application_description_1: 'Подать заявку на обучение в нашей школе просто и быстро.',
    application_description_2: 'Вы выбираете ближайший к вам кампус и остаётесь на связи.',
    application_description_3: 'Если ваши текущие знания достаточны для обучения в нашей школе, мы как можно скорее отправим вам приглашение.',
    application_button: 'Подать заявку сейчас',
    subjects: 'ПРЕДМЕТЫ',
    home_page: 'Главная страница',
    our_courses: 'Наши курсы',
    accouting: 'Бухгалтерский учёт',
    biology: 'Биология',
    chemistry: 'Химия',
    economics: 'Экономика',
    geography: 'География',
    information_technology: 'Информационные технологии',
    mathematics: 'Математика',
    physics: 'Физика',
    business: 'Бизнес',
    english: 'Английский язык',
    sign_up: 'Записаться',
    hs_diploma: 'Диплом HS',
    financial_accounting: 'Принципы финансового учёта (M)',
    international_business: 'Основы международного бизнеса (M)',
    business_leadership: 'Лидерство в бизнесе (M)',
    enviroment_resource: 'Управление ресурсами окружающей среды (M)',
    canadian_world_issues: 'Канадские и мировые проблемы (U)',
    analysing_current: 'Анализ текущих экономических проблем (U)',
    canadian_international_law: 'Канадское и международное право (U)',
    english: 'Английский (U)',
    nutrition_health: 'Питание и здоровье (U)',
    canada_families: 'Семьи в Канаде (U)',
    fashion: 'Мода (M)',
    change_society: 'Вызов и изменения в обществе (U)',
    computer_science: 'Информатика (U)',
    calculus_vectors: 'Калькулус и векторы (U)',
    advanced_functions: 'Расширенные функции (U)',
    biology_u: 'Биология (U)',
    physics_u: 'Физика (U)',
    chemistry_u: 'Химия (U)',
    communications_technology: 'Технология связи (M)',
    students_title: 'УЧЕНИКИ',
    our_graduates: 'Наши выпускники',
    campus_addresses: 'НАШИ ШКОЛЫ',
    contact: 'Контакт',
    secondary_school: 'Международная средняя школа',
    primary_classes: 'Международные начальные классы',
    call: 'Позвонить',
    message: 'Написать сообщение',
    main_title: 'CELT Средняя Школа',
    hs_diploma_title: 'Диплом HS',
    student_btn_1: 'Больше',
    student_btn_2: 'Меньше',
    narimanov_statue: 'Статуя Нариманова',
    bilgah: 'Бильгя'
}

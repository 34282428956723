import React from 'react'
import logo from '../img/logo.png'

const Loader = () => {
  return (
    <div className='w-full h-screen flex justify-center items-center'>
        <img src={logo} className='w-[220px]'/>
    </div>
  )
}

export default Loader